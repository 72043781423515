<template>
  <div
    class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full space-y-8 border rounded bg-white p-8">
      <div>
        <div class="mx-auto text-center text-2xl font-bold">
          <img src="@/assets/logo.svg" :alt="appName" />
        </div>
        <!-- <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2> -->
        <h1 class="text-center font-medium text-3xl">Passwort zurücksetzen</h1>

        <h3 class="text-xs font-medium mt-4 mb-2">
          Bitte füllen Sie das Formular aus, um das Passwort zurückzusetzen
        </h3>
        <h3 class="text-xs mb-4">
          <ul class="space-y-2">
            <li>
              1. Tragen Sie unten Ihre E-Mail Adresse ein und klicken Sie auf
              "Passwort zurücksetzen".
            </li>
            <li>
              2. Öffnen Sie die Bestätigungsemail und klicken Sie auf den Link
              um Ihr Passwort zurückzusetzen.
            </li>
          </ul>
        </h3>

        <h3
          v-if="message"
          :class="`text-center text-xs my-4 text-${messageClass}-500`"
        >
          {{ message }}
        </h3>
      </div>
      <form class="mt-8 space-y-6" action="#" method="POST">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">Email address</label>
            <input
              id="email-address"
              v-model="$v.email.$model"
              name="email"
              type="email"
              autocomplete="email"
              required
              class="appearance-none rounded w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none sm:text-sm"
              placeholder="E-Mail-Adresse"
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            @click.prevent="sendEmail"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
          >
            Passwort zurücksetzen
          </button>

          <div class="mt-4">
            <router-link to="login" class="text-blue-400 text-center block"
              >Abbrechen</router-link
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'
import { resetPassword } from '@/services/auth'

export default {
  name: 'forgot-password',
  props: ['value'],
  components: {},
  title: 'Forgot Password',
  data() {
    return {
      email: null,
      message: false,
      messageClass: 'green',
      sent: '',
    }
  },
  validations() {
    return {
      email: {
        email,
        required,
      },
    }
  },
  methods: {
    sendEmail() {
      if (!this.$v.$invalid) {
        resetPassword(this.email)
          .then(({ data }) => {
            this.messageClass = 'green'
            this.message = data.success
          })
          .catch(err => {
            console.log(err.response)
            this.messageClass = 'red'
          })
      } else {
        this.messageClass = 'red'
        this.message = 'Bitte geben Sie eine gültige E-Mail Adresse ein'
      }
    },
  },
}
</script>

<style></style>
